import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Network/IP_Configuration/SuccessBox';
import IPConfigTable from 'components/Web_User_Interface/1080p_Series/Network/IP_Configuration/ipConfigTable';
import CertCards from 'components/Web_User_Interface/1080p_Series/Network/IP_Configuration/CertCards';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // IP Configuration",
  "path": "/Web_User_Interface/1080p_Series/Network/IP_Configuration/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the Installation Wizard and don´t need to be changed. The camera´s http port might have to be changed to access your cameras webUI over the Internet. The RTSP port is the camera´s RTSP Streaming Port.",
  "image": "./WebUI_1080p_SearchThumb_Network_IP_Configuration.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Network_IP_Configuration.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Network Menu // IP Configuration' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the Installation Wizard and don´t need to be changed. The camera´s http port might have to be changed to access your cameras webUI over the Internet. The RTSP port is the camera´s RTSP Streaming Port.' image='/images/Search/WebUI_1080p_SearchThumb_Network_IP_Configuration.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Network_IP_Configuration.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Netzwerk/IP_Konfiguration/' locationFR='/fr/Web_User_Interface/1080p_Series/Network/IP_Configuration/' crumbLabel="IP Configuration" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c766a1e791a9b81cc4ec937ef8b97913/8dd93/1080p_Settings_Network_IP_Configuration.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACwklEQVQoz2XST0gUURwH8PFQppWQomKpm7a1Zaz/zXDB0FnDv2sqCVkZ6/ofUtSZ997OzPre29E8WKJFHaJLdVBCT0GRZlAQ2S3oEJTYKQLz4CHXcXdnXsxu1sEv7/LjvQ+/949DQIQQIoSQ16tISCB3+u++6Z1+3Te97Lm95J5cdE8udky+6r/3buTWI0Xyjo5ij8eTm1eQX1jEAVEcESEAgFLa6GpobG79vhH+8mNrdX1nbUNf/RU2x3pwdZPNzs17b7SL7W53XUvDOYfDlsOJIhgSIIBwTFUr+SreWc2YbughxgwWiRHS9GCAMbbyfO7hQNdkryw3tks1FU35xRyC0OwMIaV+V0PtYH+HwZiu60ZEG4wFAoGt3yZ+MbswUHNtqqd7pmfwEu8psJ/nJASHBCiI0O9X66urutpcYYOFQyG2G03TtO1txtjT+w/mh5o/q85PU1fmqTx8scTEIkAA/N12RYUzCkIRbxjGTjAU1Ez8cuHJzaaS1uKzE67jyz3py505JhYAghCqqsrzPO90GoahadpOMPivuR4OM8Yezz9Lt5w8bbWnJB+zpSXC0kQOAThsPlYUOyt5njG2EzJ0w7ywbz+3lr5urr1dYe8/zoxPHE1JtZ2yJSUlHoyP4y1xHBCBAExMKa2vr4uNjS0sKuqsK2qpKs4tKW2r5j/0ls3U5nW0Xy931VqslowTmYcT4vfvi3GkxXAIAGD+EYgxvtrWlpCYXGpNcpenHDh0JDMtFVqzcXZ6pc2aW+Vw9bU2d18ua7xgL8w9Y8/JyMriZAS90SDk8/kIIeN+SikllKqUThAKCPVijM0pPDY+RighhGCMKcH/MYpgv58SYsqox5SolPqjZcSQSKIlp3ihJHklyeSKz4cJwbvT0RV7g3fDCYIoAPOfiADJsowkCUmSLCuKokRPsVf6fD5FURBCfwBVvbaSJFi3owAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c766a1e791a9b81cc4ec937ef8b97913/e4706/1080p_Settings_Network_IP_Configuration.avif 230w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/d1af7/1080p_Settings_Network_IP_Configuration.avif 460w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/7f308/1080p_Settings_Network_IP_Configuration.avif 920w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/19805/1080p_Settings_Network_IP_Configuration.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c766a1e791a9b81cc4ec937ef8b97913/a0b58/1080p_Settings_Network_IP_Configuration.webp 230w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/bc10c/1080p_Settings_Network_IP_Configuration.webp 460w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/966d8/1080p_Settings_Network_IP_Configuration.webp 920w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/61eb3/1080p_Settings_Network_IP_Configuration.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c766a1e791a9b81cc4ec937ef8b97913/81c8e/1080p_Settings_Network_IP_Configuration.png 230w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/08a84/1080p_Settings_Network_IP_Configuration.png 460w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/c0255/1080p_Settings_Network_IP_Configuration.png 920w", "/en/static/c766a1e791a9b81cc4ec937ef8b97913/8dd93/1080p_Settings_Network_IP_Configuration.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c766a1e791a9b81cc4ec937ef8b97913/c0255/1080p_Settings_Network_IP_Configuration.png",
              "alt": "Web User Interface - 1080p Series - Network IP Configuration",
              "title": "Web User Interface - 1080p Series - Network IP Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can set whether the camera will be assigned an IP address by your router (DHCP) or, if you want to manually set a fixed address. Please check here on how to set a fixed IP address. In general, we recommend that you deactivate the automatic service and set a manual IP to avoid further trouble with the remote access to your camera.`}</p>
    <p>{`For the RTSP Port please refer to `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8015_HD/Video_Streaming/"
      }}>{`RTSP Streaming`}</a>{` for details on the use of the camera´s RTSP video stream. Remember to forward the RTSP Port if you want to access the RTSP Stream over the internet with your DDNS Address. The RTMP Port is used by the `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Live_Video/"
      }}>{`Flash Plugin`}</a>{` in your camera´s web user interface. Remember to forward the RTMP Port (alongside your LAN Port!) if you want to access the web user interface over the internet with your DDNS Address.`}</p>
    <p>{`You neither need the RTSP nor RTMP port if you only use our smartphone App to access your camera!`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IPConfigTable mdxType="IPConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "web-access-encryption",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#web-access-encryption",
        "aria-label": "web access encryption permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web Access Encryption`}</h3>
    <p>{`To access your camera´s web user interface securely, use the HTTPS port you set above. Add this port to your camera´s `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
      }}>{`DDNS Address`}</a>{` and add the `}<em parentName="p">{`https://`}</em>{` prefix, if you want to access the web user interface with your web browser - e.g. if your camera has the DDNS address abcd12.ddns-instar.de and your HTTPS port is 443, type `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://abcd12.ddns-instar.de:443"
        }}>{`https://abcd12.ddns-instar.de:443`}</a></strong>{` into your browsers address bar.`}</p>
    <p>{`Be aware that INSTAR Deutschland provided a valid encryption certificate for your DDNS address. If you try to access your camera´s local IP address over HTTPS - e.g. `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://192.168.1.20:443"
        }}>{`https://192.168.1.20:443`}</a></strong>{`, you will be confronted with a security warning. But there is no security risk in adding an exception to your browser, to access the site anyway:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <CertCards mdxType="CertCards" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      